import React, { Component } from 'react';
import Gentablet from "../../../images/screenshots/dataportal.jpg";
import Lapback from "../../../images/screenshots/datamanagementportal_b.jpg";
import WaterBlaze from "../../../images/datamanagementbanner.jpg";

const styles = {
	backbanner: {
		backgroundImage: `url(${WaterBlaze})`
	},
	hotbackpic: {
		backgroundImage: `url(${Lapback})`
	}
};

class Datamanagementinternalbanner extends Component {
	render() {

		return (
			<div>
				<section className="internalbanner" style={styles.backbanner}>

					<div className="overfunk"></div>

					<div className="container">
						<div className="columns is-marginless">

							<div className="column is-half">
								<p className="topsubtitle">Allow clients and suppliers to centralise data</p>
								<h1 className="midsize">Data Management Portal</h1>
								<p className="undersubtitle">Collaborate on the preparation and delivery of data</p>
							</div>

						</div>
					</div>
				</section>

				<section className="under-internalbanner graddown">
					<div className="threeboxes">
						<div className="container">
							<div className="boxcard norise">
								<div className="columns is-marginless">
									<div className="column is-10">

										<h2>Client-Supplier Data Management</h2><hr />
										<p>The web-based application portal enables the client and suppliers to centralise and manage data management supplied to the client across various processes</p><br />
									</div>
								</div>


								<div className="columns is-marginless">
									<div className="column is-6 pictext">
										<h3>Collaboration on data</h3>
<p class="bigpee">The portal is designed to allow the various client roles in the process to
collaborate on the preparation and delivery of data. At a higher level, the portal does the following:</p>
<ul>
<li>Document & content management</li>
<li>Reference & master data management</li>
<li>Data operations management</li>
<li>Data security management</li>
<li>Metadata management</li>
<li>Data warehousing & business intelligence management</li>
</ul>
									</div>
									<div className="column is-6 pictext">
										<figure className="fullimage">
											<img src={Gentablet} alt="Tablet screenshot" />
										</figure>
									</div>
								</div>

							</div>
						</div>
						<div className="underitems">
							<div className="overfunk"></div>
							<div className="container">
								<div className="columns is-marginless">
									<div className="column is-8">
										<p>Collaborate on the <span>preparation and delivery</span> of data</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

			</div>
		);
	}
}

export default Datamanagementinternalbanner;