import React from "react"

import "../style/bulma.css"
import "../style/coolharbour-tech.css"
import "../style/coolharbour-gentext.css"
import "../style/coolharbour-tech-internal-livelab.css"


import Layout from "../components/layout"
import Datamanagementinternalbanner from "../components/internalpages/datamanagement/datamanagementinternalbanner"

import Contactform from "../components/homepage/contactform"
import SEO from "../components/seo"
import Coolfooter from "../components/coolfooter"

const Datamanagement = () => (
  <Layout>
    <SEO title="Data Management Portal" />
    <Datamanagementinternalbanner />
   <Contactform />
   <Coolfooter />
  </Layout>
)

export default Datamanagement